import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { Box } from '@core';
import {
  LoaderWithText,
  SectionContainerSC as SectionContainer,
  ServerListTable,
  Text,
} from '@components';
import { mapOrder } from '@shared/helpers';
import { useI18nCountries } from '@hooks';

import { getFreeAndPremiumLocations, getLocationHasFeature, getPageLink } from './utils/helpers';

const CONTINENT_ORDER = ['Europe', 'North America', 'Asia', 'Australia'];

const FEATURE_NAMES = (t) => ({
  wireGuard: t('servers:serverListSection.features.wireGuard'),
  ipSec: t('servers:serverListSection.features.ipSecIkeV2'),
  privateDns: t('servers:serverListSection.features.privateDns'),
  p2p: t('servers:serverListSection.features.p2pFriendly'),
  safeSwap: t('servers:serverListSection.features.safeSwap'),
  streaming: t('servers:serverListSection.features.video'),
});

const FEATURE_VALUES = ({ location, serverData, isFreeLocation }) => ({
  wireGuard: true,
  ipSec: true,
  privateDns: true,
  p2p: true,
  safeSwap: getLocationHasFeature({
    location,
    serverData,
    featureGroupCode: 'safeswap',
    isFreeLocation,
  }),
  streaming: getLocationHasFeature({
    location,
    serverData,
    featureGroupCode: 'streaming',
    isFreeLocation,
  }),
});

const getLocationProperties = ({
  location,
  serverData,
  serverPages,
  language,
  i18nCountries,
  isFreeLocation = false,
}) => {
  const iso = location.iso_code;
  const countryName = i18nCountries.getName(iso, language, {
    select: 'alias',
  });

  // used for getPageLink() which constructs links with english country names.
  const countryNameEn = i18nCountries.getName(iso, 'en', {
    select: 'alias',
  });

  return {
    location: {
      countryName,
      iso,
      cityName: isFreeLocation ? location.city_name : null,
    },
    features: FEATURE_VALUES({ location, serverData, isFreeLocation }),
    link: getPageLink({ countryName: countryNameEn, serverPages }),
  };
};

const getServerLocations = ({ serverData, serverPages, language, i18nCountries }) => {
  if (!serverData) {
    return { premiumLocations: null, freeLocations: null };
  }

  const { premiumLocationsWithContinents, freeLocations } = getFreeAndPremiumLocations(serverData);

  const sortedPremiumServersByContinents = mapOrder(
    premiumLocationsWithContinents,
    CONTINENT_ORDER,
    'continent'
  );

  const premiumLocationsWithFeatures = sortedPremiumServersByContinents.map((item) => ({
    continent: item.continent,
    locations: item.locations.map((location) =>
      getLocationProperties({ location, serverData, serverPages, language, i18nCountries })
    ),
  }));

  const freeLocationsWithFeatures = freeLocations.map((location) =>
    getLocationProperties({
      location,
      serverData,
      serverPages,
      language,
      i18nCountries,
      isFreeLocation: true,
    })
  );

  return {
    premiumLocations: premiumLocationsWithFeatures,
    freeLocations: freeLocationsWithFeatures,
  };
};

const LoaderComponent = () => (
  <Box mx="auto">
    <LoaderWithText isLoading />
  </Box>
);

const SectionWrapper = ({ title, children }) => (
  <SectionContainer
    title={title}
    wrapperProps={{
      py: { _: 32, lg: 40 },
      pl: { _: 16, md: 40 },
      pr: { _: 16, md: 40, lg: 0, xl: 40 },
    }}
    textWrapperProps={{ pb: { _: 32, lg: 64 } }}
    containerProps={{ overflowX: 'hidden' }}
  >
    {children}
  </SectionContainer>
);

const ServerListSection = ({ serverData }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const serverPages = useStaticQuery(graphql`
    query {
      allSitePage(filter: { path: { regex: "/^/servers/{1}(?:[a-z0-9]+(?:-?[a-z0-9]+)*)+$/" } }) {
        nodes {
          path
        }
      }
    }
  `).allSitePage.nodes;
  const { i18nCountries } = useI18nCountries(language);

  const servers = useMemo(
    () =>
      i18nCountries &&
      serverData &&
      getServerLocations({
        serverData,
        serverPages,
        language,
        i18nCountries,
      }),
    [i18nCountries, language, serverData, serverPages]
  );

  return (
    <React.Fragment>
      <SectionWrapper title={t('servers:serverListSection.premiumLocations')}>
        {servers?.premiumLocations ? (
          servers?.premiumLocations.map(({ continent, locations }, index) => (
            <Box mx={{ xl: 'auto' }} key={continent}>
              <Text.Body1Strong mb={8}>{continent}</Text.Body1Strong>
              <Box mb={index !== servers?.premiumLocations.length - 1 && 32}>
                <ServerListTable data={locations} featureNames={FEATURE_NAMES(t)} />
              </Box>
            </Box>
          ))
        ) : (
          <LoaderComponent />
        )}
      </SectionWrapper>

      <SectionWrapper title={t('servers:serverListSection.freeLocations')}>
        {servers?.freeLocations ? (
          <Box mx={{ xl: 'auto' }}>
            <ServerListTable data={servers?.freeLocations} featureNames={FEATURE_NAMES(t)} />
          </Box>
        ) : (
          <LoaderComponent />
        )}

        <Text.Body3 color="darkBlue_60" textAlign="center" mt={24}>
          {t('common:wireGuardTrademark')}
        </Text.Body3>
      </SectionWrapper>
    </React.Fragment>
  );
};

export default ServerListSection;
