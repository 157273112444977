/**
 * custom slugs for /servers/ inner pages that do not match with country name received from backend
 * used in servers page server list table to generate links
 * update when there is a need for a new custom slug
 */
export const CUSTOM_SLUGS = {
  'united-states': 'usa',
  'united-arab-emirates': 'uae',
  'czech-republic': 'czech',
};
