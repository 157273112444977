import { filterByUniqueKey, sortDataByKey } from '@shared/helpers';
import { toKebabCase } from '@shared/format-helpers';

import { CUSTOM_SLUGS } from '@shared/constants/custom-slugs';

const getServersByGroup = ({ serverData, groupCode }) =>
  serverData?.groups?.find((group) => group.code === groupCode)?.servers || [];

const getLocationsByServerIds = ({ serverData, serverIds }) => {
  const serverLocationIds = serverData?.servers
    .filter((server) => serverIds.includes(server.id))
    .map((server) => server.location_id);
  const serverLocations = [];
  return serverLocations;
};

const getSortedServerGroupLocations = ({ serverData, groupCode, sortKey, filterKey }) => {
  const groupServerIds = getServersByGroup({ serverData, groupCode });
  const serverLocations = getLocationsByServerIds({ serverData, serverIds: groupServerIds });
  const sortedServerLocations = filterKey
    ? sortDataByKey(filterByUniqueKey(serverLocations, filterKey), sortKey)
    : sortDataByKey(serverLocations, sortKey);

  return sortedServerLocations;
};

export const getFreeAndPremiumLocations = (serverData) => {
  const sortedPremiumServerLocations = getSortedServerGroupLocations({
    serverData,
    groupCode: 'premium',
    sortKey: 'country_name',
    filterKey: 'country_name',
  });

  const premiumLocationsWithContinents = serverData?.continents?.reduce((continentsArray, item) => {
    const continentObject = {
      continent: item.name,
      locations: sortedPremiumServerLocations.filter(
        (location) => location.continent === item.name
      ),
    };
    continentsArray.push(continentObject);
    return continentsArray;
  }, []);

  // free locations differently than premium are not filtered by unique country
  const freeLocations = getSortedServerGroupLocations({
    serverData,
    groupCode: 'free',
    sortKey: 'country_name',
  });

  return { premiumLocationsWithContinents, freeLocations };
};

export const getLocationHasFeature = ({
  location,
  featureGroupCode,
  serverData,
  isFreeLocation,
}) => {
  const allFeatureGroupServers = getServersByGroup({ serverData, groupCode: featureGroupCode });
  const allPricingGroupServers = getServersByGroup({
    serverData,
    groupCode: isFreeLocation ? 'free' : 'premium',
  });
  const featureServersInCurrentPricingGroup = allFeatureGroupServers.filter((server) =>
    allPricingGroupServers.includes(server)
  );
  const featureLocationsByServers = getLocationsByServerIds({
    serverData,
    serverIds: featureServersInCurrentPricingGroup,
  });
  const locationHasFeature = featureLocationsByServers.some(
    (featureLocation) => featureLocation.id === location.id
  );

  return locationHasFeature;
};

export const getPageLink = ({ countryName, serverPages }) => {
  const slugCountryName = toKebabCase(countryName);
  const pagePaths = serverPages.map((page) => page.path);

  return pagePaths.find(
    (path) =>
      `/servers/${slugCountryName}` === path || `/servers/${CUSTOM_SLUGS[slugCountryName]}` === path
  );
};
